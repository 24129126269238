<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="d-flex justify-content-between align-items-center px-1 py-50">
            <div class="col-3" >
              <h3 class="m-0" >{{ $t('Proyectos') }}</h3>
            </div>
            <div class="d-flex align-items-center" >
              <div>
                <b-link
                    :to="{ name: 'createProject' }"
                >
                  <b-button
                      variant="primary"
                      size="sm"
                      class="mr-1 text-nowrap"
                  >
                    {{ $t('Crear nuevo') }}
                  </b-button>
                </b-link>
              </div>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                    v-model="searchTerm"
                    type="text"
                    class="d-inline-block"
                    :placeholder="$t('Busqueda')"
                    @input="handleSearch"
                />
              </b-input-group>
            </div>
          </div>
          <b-table
              hover
              responsive
              :items="items"
              :fields="columns"
          >
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editProject', params: { id: data.item.id} }"
                  class="mr-1"
                >
                  <feather-icon icon="Edit2Icon" size="16 "/>
                </b-link>
                <span
                    class="text-danger cursor-pointer"
                    @click="deleteProject( data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              {{ $t('PorPagina') }}:
            </span>
            <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ $t('Total') }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination, BFormInput, BFormSelect, BInputGroup, BInputGroupPrepend, BButton, BCard, BTable, BCol, BRow, BLink,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Referencia',
          key: 'reference',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'text-right',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      items: 'projects/getItems',
      totalItems: 'projects/getTotalItems',
    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Desactivado',
      }

      return status => statusText[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'projects/getListProjects',
      delete: 'projects/delete',
    }),
    chargeData() {
      this.list({
        client: this.currentClient.id, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteProject(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t('EliminarConfirm', {nombre: name}), {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>
